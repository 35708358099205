<template>
  <div class="scList">
    <div v-if="contentList1.length<1" style="position: relative;">
      <nodata></nodata>
    </div>
      <div v-html="form" class="text"></div>
      <div class="boxFrom" v-for="i in contentList1" :key="i.id" @click="btnDetails(i)" v-if="contentList1.length>0">
         <van-cell :title="i.title" is-link @click="btnDetails(i)">
            <template #icon>
              <img class="icon" :src="i.coverPhoto" alt="">
            </template>
          </van-cell>
      </div>
    </div>
</template>

<script>
import {getSceneContentByColumnId} from '@/api/home.js'

export default {
  props:['contentList1','form'],
  name:"scList",
  data() {
    return {
      id:'',
      list: [],
      loading: false,
      finished: false,
      value:'',
      contentData:[],
      typeId:'',
      page:1,
      contentList:[],
    }
  },
  created(){
  },
  mounted(){
    // if(this.$store.state.jxbApp){
    //   window.location.href=`ssptitle://${this.topTittle}`
    // }else{
    //   document.title = this.topTittle
    // }
    
  },
  watch:{
  },
  computed:{
    listId(){
      return this.$store.state.listId
    },
    // contentList(){
    //     return this.contentList1
    // }
  },
  methods:{
    // async onSearch(){
    //     let data = await getSceneContentByColumnId({
    //       "columnId": this.$route.query.id,
    //       "pageNumber": 1,
    //       "pageSize": 10,
    //       "title": this.value
    //     })
    //     let arr = data.data.data.rows
    //     arr.map((item)=>{
    //         if(item.tagInfo){
    //           item.tagInfo = item.tagInfo.split(',')
    //         }
    //     })
    //     this.contentList = arr
    // },
    // 查看下一步
    btnDetails(item){
      // console.log(item)
        // this.$store.commit('setTopTittle',item.title)
        localStorage.setItem('tittle',item.title)
        if(item.detailType == 1){
            if(item.type == 1){
              this.$router.push({path:"/scContent",query:{typeId:1,id:item.id}})
            }else if(item.type == 2){
              this.$router.push({path:"/scContent",query:{typeId:2,id:item.id}})
            }else{
              this.$router.push({path:"/scContent",query:{typeId:3,id:item.id}})
            }
          }else{
            this.$router.push({path:"/scContent",query:{typeId:4,id:item.id}})
          }
    }
  }
}
</script>

<style lang="less" scoped>
.icon{
  width: .596rem;
  height: .596rem;
  margin-right: .204rem;
}
/deep/.van-cell::after{
  border-bottom:none
}
/deep/.van-cell{
  border-bottom: .009rem solid #ebedf0;
  align-items: center;
  
  .van-cell__title, .van-cell__value{
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.tittle{
  color: #000000;
  font-size: .498rem;
  font-weight: 400;
  margin-left: .453rem;
}
</style>
